import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

// material ui
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Collapse from '@material-ui/core/Collapse';

import ExtendedSearchSmartSearchTab from './ExtendedSearchSmartSearchTab';
import ExtendedSearchCategorySearchTab from './ExtendedSearchCategorySearchTab';
import ExtendedSearchGpsSearchTab from './ExtendedSearchGpsSearchTab';
import ExtendedSearchDualSearchTab from './ExtendedSearchDualSearchTab';
import { MAIN_SEARCH_BIB, MAIN_SEARCH_FACE } from '../../../utils/variables';
import { customScrollToComponent } from '../../../utils/common';

const SMART_SEARCH = 0;
const CATEGORY_SEARCH = 1;
const GPS_SEARCH = 2;
const DUAL_SEARCH = 3;

const ExtendedSearch = (props) => {
  const {
    intl,
    event,
    startnumber,
    searchStartnumber,
    selectedTab,
    expand,
    selectedCategoryId,
    hasSmartSearchTab,
    hasCategorySearchTab,
    hasGpsSearchTab,
    hasDualSearchTab,
    onTabChange,
    codeSecondFactorForFaceEvent,
  } = props;

  const isSmartSearchPage =
    props.match.path === '/:language/event/:id/smartsearch/:startnumber';

  let location = useLocation();

  const notOnMainSearchPage = () => {
    return /dual|category|gps|smartsearch/.test(location.pathname);
  };

  const extendedSearchRef = useRef(null);

  useEffect(() => {
    if (expand) {
      customScrollToComponent(extendedSearchRef.current);
    }
  }, [expand]);

  const handleNavigateTo = (url) => {
    let fullUrl = url;
    if (codeSecondFactorForFaceEvent || location.search) {
      const codeAsSearchParam = codeSecondFactorForFaceEvent
        ? `?code=${codeSecondFactorForFaceEvent}`
        : location.search;
      fullUrl = `${fullUrl}${codeAsSearchParam}`;
    }

    if (props.match.path === '/:language/event/:id') {
      props.history.push(fullUrl);
    } else {
      props.history.replace(fullUrl);
    }
  };

  const isOnMainPage = notOnMainSearchPage();

  const collectionsAvailable = event.searchCollections;

  return (
    <Collapse in={expand} timeout="auto">
      <div className="row" ref={extendedSearchRef}>
        <div
          className={`col-xs-16 col-sm-12 col-sm-offset-2 ${
            isOnMainPage && 'margin-bottom-tabs'
          }`}
        >
          <div>
            <Tabs
              classes={{ indicator: 'extended-search-tab-indicator' }}
              onChange={onTabChange}
              value={selectedTab}
              variant="fullWidth"
            >
              {hasDualSearchTab &&
                event.mainSearch === MAIN_SEARCH_BIB &&
                startnumber && (
                  <Tab
                    classes={{ root: 'extended-search-tab' }}
                    label={intl.formatMessage(translations.faceSearchTabTitle)}
                    value={DUAL_SEARCH}
                  />
                )}
              {hasDualSearchTab &&
                event.mainSearch === MAIN_SEARCH_FACE &&
                startnumber && (
                  <Tab
                    classes={{ root: 'extended-search-tab' }}
                    label={intl.formatMessage(translations.snSearchTabTitle)}
                    value={DUAL_SEARCH}
                  />
                )}
              {hasSmartSearchTab && (
                <Tab
                  classes={{ root: 'extended-search-tab' }}
                  label={intl.formatMessage(translations.smartSearchTabTitle)}
                  value={SMART_SEARCH}
                />
              )}
              {hasCategorySearchTab && collectionsAvailable && (
                <Tab
                  classes={{ root: 'extended-search-tab' }}
                  label={intl.formatMessage(translations.categorySearchTabTitle)}
                  value={CATEGORY_SEARCH}
                />
              )}
              {hasGpsSearchTab && (
                <Tab
                  classes={{ root: 'extended-search-tab' }}
                  label={intl.formatMessage(translations.gpsSearchTabTitle)}
                  value={GPS_SEARCH}
                />
              )}
            </Tabs>
            <div className="extended-search-tab-content">
              {selectedTab === SMART_SEARCH && (
                <ExtendedSearchSmartSearchTab
                  eventId={event.sgId}
                  startnumber={startnumber}
                  isSmartSearchPage={isSmartSearchPage}
                  eventHasSearchByFace={event.mainSearch === MAIN_SEARCH_FACE}
                />
              )}
              {selectedTab === CATEGORY_SEARCH && (
                <ExtendedSearchCategorySearchTab
                  eventId={event.sgId}
                  startnumber={startnumber}
                  categories={event}
                  selectedCategoryId={selectedCategoryId}
                  onNavigateTo={handleNavigateTo}
                />
              )}
              {selectedTab === GPS_SEARCH && (
                <ExtendedSearchGpsSearchTab
                  eventId={event.sgId}
                  startnumber={startnumber}
                  onNavigateTo={handleNavigateTo}
                />
              )}
              {selectedTab === DUAL_SEARCH && (
                <ExtendedSearchDualSearchTab
                  event={event}
                  startnumber={startnumber}
                  searchStartnumber={searchStartnumber}
                  onNavigateTo={handleNavigateTo}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Collapse>
  );
};

const translations = defineMessages({
  smartSearchTabTitle: {
    id: 'extendedSearch.smartSearch.tabTitle',
    defaultMessage: 'Smart',
  },
  categorySearchTabTitle: {
    id: 'extendedSearch.searchAfterCategory.tabTitle',
    defaultMessage: 'Categories',
  },
  gpsSearchTabTitle: {
    id: 'extendedSearch.gpsSearch.tabTitle',
    defaultMessage: 'GPS',
  },
  faceSearchTabTitle: {
    id: 'extendedSearch.faceSearch.tabTitle',
    defaultMessage: 'Face',
  },
  snSearchTabTitle: {
    id: 'extendedSearch.snSearch.tabTitle',
    defaultMessage: 'Bib Number',
  },
});

ExtendedSearch.defaultProps = {
  searchStartnumber: '',
  hasSmartSearchTab: true,
  hasCategorySearchTab: true,
  hasGpsSearchTab: false,
  hasDualSearchTab: false,
};

ExtendedSearch.propTypes = {
  event: PropTypes.object,
  startnumber: PropTypes.string,
  searchStartnumber: PropTypes.string,
  selectedTab: PropTypes.number,
  expand: PropTypes.bool,
  selectedCategoryId: PropTypes.string,
  hasSmartSearchTab: PropTypes.bool,
  hasCategorySearchTab: PropTypes.bool,
  hasGpsSearchTab: PropTypes.bool,
  hasDualSearchTab: PropTypes.bool,
  onTabChange: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
  codeSecondFactorForFaceEvent: PropTypes.string,
};

export { ExtendedSearch };

export default createFragmentContainer(injectIntl(withRouter(ExtendedSearch)), {
  event: graphql`
    fragment ExtendedSearch_event on Event {
      id
      sgId
      mainSearch
      searchCollections
      ...ExtendedSearchCategorySearchTab_categories
      ...ExtendedSearchDualSearchTab_event
    }
  `,
});
