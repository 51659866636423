/**
 * @flow
 * @relayHash d562788f4c11c0cee941e9031985a482
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StartNumberSearch_info$ref = any;
export type StartNumberSearchRefetchQueryVariables = {|
  eventId: string,
  individualPart?: ?string,
|};
export type StartNumberSearchRefetchQueryResponse = {|
  +viewer: ?{|
    +event: ?{|
      +secondFactorEventSalt: ?string,
      +$fragmentRefs: StartNumberSearch_info$ref,
    |}
  |}
|};
export type StartNumberSearchRefetchQuery = {|
  variables: StartNumberSearchRefetchQueryVariables,
  response: StartNumberSearchRefetchQueryResponse,
|};
*/


/*
query StartNumberSearchRefetchQuery(
  $eventId: ID!
  $individualPart: String
) {
  viewer {
    event(id: $eventId) {
      secondFactorEventSalt(individualPart: $individualPart)
      ...StartNumberSearch_info
      id
    }
    id
  }
}

fragment StartNumberSearch_info on Event {
  id
  gps
  mainSearch
  searchDual
  secondFactorEventSalt(individualPart: $individualPart)
  secondFactorType {
    type
    id
  }
  sgId
  hostResultUrl
  warnText
  faceWebcamOnly
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "individualPart",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "secondFactorEventSalt",
  "args": [
    {
      "kind": "Variable",
      "name": "individualPart",
      "variableName": "individualPart"
    }
  ],
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StartNumberSearchRefetchQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "Event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "StartNumberSearch_info",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StartNumberSearchRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "Event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "gps",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "mainSearch",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "searchDual",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "secondFactorType",
                "storageKey": null,
                "args": null,
                "concreteType": "SecondFactorType",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "sgId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hostResultUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "warnText",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "faceWebcamOnly",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "StartNumberSearchRefetchQuery",
    "id": null,
    "text": "query StartNumberSearchRefetchQuery(\n  $eventId: ID!\n  $individualPart: String\n) {\n  viewer {\n    event(id: $eventId) {\n      secondFactorEventSalt(individualPart: $individualPart)\n      ...StartNumberSearch_info\n      id\n    }\n    id\n  }\n}\n\nfragment StartNumberSearch_info on Event {\n  id\n  gps\n  mainSearch\n  searchDual\n  secondFactorEventSalt(individualPart: $individualPart)\n  secondFactorType {\n    type\n    id\n  }\n  sgId\n  hostResultUrl\n  warnText\n  faceWebcamOnly\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f1118b290ae121fb3966c409eee0b5ec';

module.exports = node;
