import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessageWrappedInSpan } from '../../misc';
import { ACCOUNT, ALTERNATIVE, PARTICIPANT } from '../../../utils/variables';

const StillProcessingImagesInfo = ({ onBuyFotoFlat, version, onGoBackToOffer }) => {
  return (
    <div
      className={classNames('processing-images-info  mb-40 mt-20', {
        'mt-20': version === PARTICIPANT,
      })}
    >
      <div className="processing-images-info_texts">
        <h1>
          <FormattedMessageWrappedInSpan
            id="stillProcessingImagesInfo.heading"
            defaultMessage="We are still processing - More images might come!"
          />
        </h1>
        <div className="processing-images-info_progress-bar">
          <div className="progress-bar_root">
            <div className="progress-bar_bar" style={{ width: '90%' }}>
              <div className="animated-dot"></div>
            </div>
          </div>
        </div>
        {version === PARTICIPANT && (
          <FormattedMessageWrappedInSpan
            id="stillProcessingImagesInfo.message"
            defaultMessage="<b>Buy now</b> - if we find more, you will get them automatically and <b>for free</b>."
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        )}
        {version === ALTERNATIVE && (
          <FormattedMessageWrappedInSpan
            id="stillProcessingImagesInfo.alternativeMessage"
            defaultMessage="<b>Buy now</b> - you can add missing photos of yourself to your Foto-Flat at any time <b>for free</b>."
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        )}
        {version === ACCOUNT && (
          <FormattedMessageWrappedInSpan
            id="stillProcessingImagesInfo.accountMessage"
            defaultMessage="If we find more photos of you, we will add them here automatically and <b>for free</b>."
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        )}
      </div>
      {(onBuyFotoFlat || onGoBackToOffer) && (
        <div className="processing-images-info_actions">
          {/* WAITING FOR BACKEND CHANGES 
          <button className="btn btn-default info-btn  full-width">
            <FormattedMessageWrappedInSpan
              id="stillProcessingImagesInfo.actions.cancel"
              defaultMessage="Notify to buy when processed"
            />
          </button> */}
          {onBuyFotoFlat && (
            <button
              onClick={() => onBuyFotoFlat()}
              className="btn btn-default info-btn main-action full-width"
            >
              <FormattedMessageWrappedInSpan
                id="stillProcessingImagesInfo.actions.buyNow"
                defaultMessage="Buy now"
              />
            </button>
          )}
          {onGoBackToOffer && (
            <button
              onClick={() => onGoBackToOffer()}
              className="btn btn-default info-btn main-action full-width"
            >
              <FormattedMessageWrappedInSpan
                id="stillProcessingImagesInfo.actions.goBack"
                defaultMessage="Go to offer"
              />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

StillProcessingImagesInfo.defaultProps = {
  version: 'participant',
};

StillProcessingImagesInfo.propTypes = {
  onBuyFotoFlat: PropTypes.func,
  version: PropTypes.oneOf(['participant', 'account', 'alternative']),
  onGoBackToOffer: PropTypes.func,
};

export default StillProcessingImagesInfo;
