import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import CertificateDownloadForm from './CertificateDownloadForm';
import CustomDialog from '../dialogs/CustomDialog';
import AccountDownloadTile from '../account/AccountDownloadTile';
import GenerateEventCertificateMutation from '../../../mutations/GenerateEventCertificateMutation';
import environment from '../../../environment';
import {
  CERTIFICATE_SELECTED_PHOTO,
  CERTIFICATE_UNKNOWN_BIB,
  SINGLE_MEDIA_ACCOUNT_PAGE,
} from '../../../utils/variables';

const ThumbnailPreviewCertificateDownload = (props) => {
  const { photoNodeId, isFaceSearchEvent, eventSgId } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [failed, setFailed] = useState(false);

  const { bibnumber } = useParams();

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const handleDownloadCertificate = (startNumber) => {
    addToPendingArray(startNumber);
    GenerateEventCertificateMutation.commit(
      environment,
      eventSgId,
      photoNodeId,
      startNumber,
      (error, url) => {
        if (!error) {
          setFailed(false);
          download(url);
        } else {
          setFailed(true);
        }
        removeFromPendingArray(startNumber);
      }
    );
  };

  const download = (url) => {
    window.location.href = url;
  };

  const addToPendingArray = (startNumber) => {
    let newArr = [...pendingRequests];
    newArr.push(startNumber);
    setPendingRequests(newArr);
  };

  const removeFromPendingArray = (startNumber) => {
    const newArr = pendingRequests.filter((item) => item !== startNumber);
    setPendingRequests(newArr);
  };

  return (
    <div className="thumbnail-btn-wrapper">
      {bibnumber && bibnumber != SINGLE_MEDIA_ACCOUNT_PAGE && !isFaceSearchEvent && (
        <AccountDownloadTile
          type={CERTIFICATE_SELECTED_PHOTO}
          onCertificateDownloadClick={() => handleDownloadCertificate(bibnumber)}
          isPending={pendingRequests.length > 0}
          isError={failed}
        />
      )}
      {(isFaceSearchEvent || bibnumber === SINGLE_MEDIA_ACCOUNT_PAGE) && (
        <>
          <AccountDownloadTile
            type={CERTIFICATE_UNKNOWN_BIB}
            onOpenCertificateDialog={openDialog}
          />
          <CustomDialog open={dialogOpen} onRequestClose={closeDialog}>
            <CertificateDownloadForm
              onDownloadCertificate={handleDownloadCertificate}
              pendingRequests={pendingRequests}
              failed={failed}
            />
          </CustomDialog>
        </>
      )}
    </div>
  );
};

ThumbnailPreviewCertificateDownload.propTypes = {
  photoNodeId: PropTypes.string,
  startNumbers: PropTypes.array,
  isFaceSearchEvent: PropTypes.bool,
  eventSgId: PropTypes.number,
};

export default ThumbnailPreviewCertificateDownload;
export { ThumbnailPreviewCertificateDownload };
