/* global MEDIA_FETCH_COUNT: true */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import EventSnSearchPage from './EventSnSearchPage';
import QueryHandler from '../components/misc/QueryHandler';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

class EventSnSearchPageQueryRenderer extends Component {
  
  constructor(props) {
    super(props);

    this.eventIdFromUrl = props.match.params.id;
  }
  render() {
    const { match, location } = this.props;
    const secondFactorFromUrl = match.params.customerKey;
    const queryParams = new URLSearchParams(location.search);
    const code2FA = queryParams.get('code');

    return (
      <QueryHandler
        query={graphql`
          query EventSnSearchPageQueryRendererQuery(
            $eventId: ID!
            $startnumber: ID
            $count: Int!
            $cursor: String
            $language: String
            $secondFactor: String
            $individualPart: String,
            $isRecheckPage: Boolean!,
          ) {
            viewer {
              ...EventSnSearchPage_viewer
            }
          }
        `}
        variables={{
          eventId: this.eventIdFromUrl,
          startnumber: match.params.startnumber ? match.params.startnumber : "",
          count: MEDIA_FETCH_COUNT,
          language: match.params.language,
          secondFactor: code2FA || secondFactorFromUrl ,
          individualPart: '',
          isRecheckPage: false,
        }}
        redirectTo={`/event/${this.eventIdFromUrl}`}
        render={props => {
          return (
            <EventSnSearchPage
              viewer={props.viewer}
              snFromUrl={match.params.startnumber}
              customerKey={code2FA || match.params.customerKey}
              codeSecondFactorForFaceEvent={code2FA}
            />
          );
        }}
      />
    );
  }
}

EventSnSearchPageQueryRenderer.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default EventSnSearchPageQueryRenderer;
