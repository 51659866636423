import React, { useState, Fragment, useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { FormattedMessageWrappedInSpan } from '../components/misc';
import includes from 'lodash.includes';
import classNames from 'classnames';
import { useHistory, Redirect } from 'react-router-dom';

import EventInfoCard from '../components/content/event/EventInfoCard';
import MediaList from '../components/content/lists/MediaList';
import PhotoBookInfoBanner from '../components/content/photo-book/PhotoBookInfoBanner';
import AccountDownloadTile from '../components/content/account/AccountDownloadTile';
import StillProcessingImagesInfo from '../components/content/informations/StillProcessingImagesInfo';
import { customScrollToComponent } from '../utils/common';

import {
  includesPhotoBook,
  getVideo,
  includesVideo,
  includesFotoFlat,
} from '../utils/common';
import {
  ORIGINAL,
  SOCIAL_MEDIA,
  SINGLE_PHOTO,
  VIDEO,
  CERTIFICATE,
  VOUCHER,
  MAIN_SEARCH_FACE,
  RESTART_STATE_CAN_RESTART,
  RESTART_STATE_PROCESSING,
  RESTART_PRODUCTION,
  SINGLE_MEDIA_ACCOUNT_PAGE,
  ACCOUNT,
} from '../utils/variables';

const AccountEventPage = (props) => {
  const { intl, viewer, match } = props;
  const { account } = viewer;
  const bibNumberForPage = match.params.bibnumber;
  const [dataForBibNumber] = account.eventPurchases.participants.filter(
    (eventPurchaseInfo) => eventPurchaseInfo.startnumber === bibNumberForPage
  );
  const isSingleMediaPage = bibNumberForPage === SINGLE_MEDIA_ACCOUNT_PAGE;
  const availableZips = viewer.account.eventPurchases.availableZips ?? [];
  const availableCertificates = viewer.account.eventPurchases.availableCertificates ?? [];
  const restartState = account.eventPurchases.restartState;
  const originalsAreAvailable = includes(availableZips, ORIGINAL);
  const socialMediaAreAvailable = includes(availableZips, SOCIAL_MEDIA);
  const isFaceSearchEvent = viewer.account.boughtEvent.mainSearch === MAIN_SEARCH_FACE;
  const hasVideo = includesVideo(viewer.account.boughtEvent.products);
  const hasBoughtFotoFlat =
    includesFotoFlat(viewer.account.boughtEvent.products) &&
    availableZips && availableZips.some(
      (value) => value !== ORIGINAL && value !== SOCIAL_MEDIA
    );
  const videoReadyForDownload = hasVideo
    ? getVideo(viewer.account.boughtEvent.products).price.amount == 0
    : null;
  const hasCertificates = availableCertificates?.length > 0;
  const hasPhotoBook = includesPhotoBook(viewer.account.boughtEvent.products);
  const mediaListRef = useRef(null);
  const stillProcessingInfoRef = useRef(null);

  const history = useHistory();

  const findEventVouchers = () => {
    const filteredOrders = account.orders?.edges.filter(
      (order) =>
        order.node.cart.lineItems.some(
          (item) => item.product.event.sgId === viewer.account.boughtEvent.sgId
        ) && order.node.vouchers.length > 0
    );
    return filteredOrders?.map((order) => ({
      vouchers: order.node.vouchers,
      orderid: order.node.orderid,
    }));
  };
  const vouchersForEvent = findEventVouchers();

  const handleCustomizePhotoBookClick = () => {
    const { history, match } = props;
    history.push(`/account/event/${match.params.id}/book`);
  };

  const scrollToFotoFlat = () => {
    if (stillProcessingInfoRef) {
      customScrollToComponent(stillProcessingInfoRef.current);
    } else {
      customScrollToComponent(mediaListRef.current);
    }
  };

  if (
    (!dataForBibNumber && !isSingleMediaPage) ||
    (isSingleMediaPage && account.eventPurchases.singleMedia.mediaInfo.count === 0)
  ) {
    // check if user didn't buy anything for that event+bib
    return <Redirect to="/account/events" />;
  }

  return (
    <div className="container-960 container-page">
      <div className="account-event-header">
        <div className="row account-event-info-card">
          <EventInfoCard
            eventInfo={viewer.account.boughtEvent}
            eventBannerActAsLink={true}
            handleScrollToOffer={scrollToFotoFlat}
            shouldShowAdditionalOffer={false}
            startnumber={bibNumberForPage}
            onUseNavigation={() => history.push('/account/events')}
          />
          <div className="col-xs-16 col-sm-6">
            <div className="account-download-container">
              <div className="account-download-body">
                <div className={classNames('container-label ml-15 mr-15 mb-15')}>
                  <FormattedMessageWrappedInSpan
                    id="accountEventPage.products.title"
                    defaultMessage="Download area"
                  />
                </div>
                <div className="account-download-btns">
                  <AccountDownloadTile
                    type={SINGLE_PHOTO}
                    onScrollToMedia={scrollToFotoFlat}
                  />
                  {hasBoughtFotoFlat && socialMediaAreAvailable && !isSingleMediaPage && (
                    <AccountDownloadTile
                      eventSgId={viewer.account.boughtEvent.sgId}
                      type={SOCIAL_MEDIA}
                      values={SOCIAL_MEDIA}
                    />
                  )}
                  {hasBoughtFotoFlat &&
                    (originalsAreAvailable ||
                      restartState === RESTART_STATE_PROCESSING) &&
                    !isSingleMediaPage && (
                      <AccountDownloadTile
                        type={ORIGINAL}
                        values={ORIGINAL}
                        eventSgId={viewer.account.boughtEvent.sgId}
                        areProcessed={restartState === RESTART_STATE_PROCESSING}
                      />
                    )}

                  {hasCertificates && hasBoughtFotoFlat && (
                    <AccountDownloadTile
                      type={CERTIFICATE}
                      onScrollToMedia={scrollToFotoFlat}
                      notActive={!originalsAreAvailable}
                    />
                  )}
                  {hasVideo && hasBoughtFotoFlat && !isSingleMediaPage && (
                    <AccountDownloadTile
                      values={bibNumberForPage}
                      type={VIDEO}
                      notActive={!videoReadyForDownload}
                      isFaceSearchEvent={isFaceSearchEvent}
                      eventSgId={viewer.account.boughtEvent.sgId}
                      intl={intl}
                    />
                  )}
                  {vouchersForEvent && vouchersForEvent.length > 0 && (
                    <AccountDownloadTile type={VOUCHER} values={[...vouchersForEvent]} />
                  )}
                  {(!originalsAreAvailable || isSingleMediaPage) &&
                    restartState === RESTART_STATE_CAN_RESTART && (
                      <AccountDownloadTile
                        type={RESTART_PRODUCTION}
                        eventSgId={viewer.account.boughtEvent.sgId}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {hasPhotoBook && (
          <div className="row">
            <div className="col-xs-16 col-sm-12 col-sm-offset-2">
              <PhotoBookInfoBanner
                eventId={viewer.account.boughtEvent.sgId}
                eventName={viewer.account.boughtEvent.name}
                onContinueClick={handleCustomizePhotoBookClick}
              />
            </div>
          </div>
        )}
      </div>
      {viewer.account.boughtEvent.pendingMediaProcessing && (
        <div className="row">
          <div
            ref={stillProcessingInfoRef}
            className="col-xs-16 col-sm-12 col-sm-offset-2"
          >
            <StillProcessingImagesInfo version={ACCOUNT} />
          </div>
        </div>
      )}
      <div ref={mediaListRef}>
        {hasBoughtFotoFlat && !isSingleMediaPage && (
          <>
            <div className="row">
              <div className="col-xs-16 col-sm-12 col-sm-offset-2 account-media-header">
                <h1 className="separator">
                  {viewer.account.boughtEvent.mainSearch === MAIN_SEARCH_FACE
                    ? intl.formatMessage(translations.boughtPhotosFaceEvent, {
                        count: dataForBibNumber.media.mediaInfo.count,
                      })
                    : intl.formatMessage(translations.boughtPhotos, {
                        count: dataForBibNumber.media.mediaInfo.count,
                        startNumber: dataForBibNumber.startnumber,
                      })}
                  {viewer.account.boughtEvent.mainSearch === MAIN_SEARCH_FACE && (
                    <span>
                      {intl.formatMessage(translations.boughtPhotosFFVirtualId, {
                        virtualStartNumber: dataForBibNumber.startnumber,
                      })}
                    </span>
                  )}
                </h1>
              </div>
            </div>
            <MediaList
              media={dataForBibNumber.media.mediaList}
              offer={null}
              eventSgId={viewer.account.boughtEvent.sgId}
              startNumbers={account.eventPurchases.availableCertificates}
              listType="account"
              restartState={restartState}
              isFaceSearchEvent={isFaceSearchEvent}
              hasCertificates={hasCertificates}
            />
          </>
        )}
      </div>
      {account.eventPurchases.singleMedia.mediaInfo.count > 0 && isSingleMediaPage && (
        <Fragment>
          <div className="row">
            <div className="col-xs-16 col-sm-12 col-sm-offset-2">
              <h1 className="separator">
                {intl.formatMessage(translations.singleMedia, {
                  count: account.eventPurchases.singleMedia.mediaInfo.count,
                })}
              </h1>
            </div>
          </div>
          <MediaList
            media={account.eventPurchases.singleMedia.mediaList}
            offer={null}
            eventSgId={viewer.account.boughtEvent.sgId}
            startNumbers={account.eventPurchases.availableCertificates}
            listType="account"
            restartState={restartState}
            isFaceSearchEvent={isFaceSearchEvent}
            hasCertificates={hasCertificates}
          />
        </Fragment>
      )}

      {account.eventPurchases.bonusMedia.mediaInfo.count > 0 && !isSingleMediaPage && (
        <Fragment>
          <div className="row">
            <div className="col-xs-16 col-sm-12 col-sm-offset-2">
              <h1 className="separator">
                {intl.formatMessage(translations.freePhotos, {
                  count: account.eventPurchases.bonusMedia.mediaInfo.count,
                })}
              </h1>
            </div>
          </div>
          <MediaList
            media={account.eventPurchases.bonusMedia.mediaList}
            eventSgId={viewer.account.boughtEvent.sgId}
            offer={null}
            listType="account"
            restartState={account.eventPurchases.restartState}
          />
        </Fragment>
      )}
    </div>
  );
};

const translations = defineMessages({
  boughtPhotos: {
    id: 'accountEventPage.boughtPhotos',
    defaultMessage:
      'Listing {count, plural, one {# photo} other {# photos}} for bib number {startNumber}',
  },
  boughtPhotosFaceEvent: {
    id: 'accountEventPage.boughtPhotosFaceEvent',
    defaultMessage: 'Listing {count, plural, one {# photo} other {# photos}} you bought',
  },
  boughtPhotosFFVirtualId: {
    id: 'accountEventPage.boughtPhotosFFVirtualId',
    defaultMessage: 'Foto-Flat ID {virtualStartNumber}',
  },
  singleMedia: {
    id: 'accountEventPage.singleMedia',
    defaultMessage:
      'Listing {count, plural, one {# photo} other {# photos}} you bought for this event',
  },
  freePhotos: {
    id: 'accountEventPage.freePhotos',
    defaultMessage:
      'Listing {count, plural, one {# free photo} other {# free photos}} for this event',
  },
  requestVideoButton: {
    id: 'accountEventPage.requestVideoButton',
    defaultMessage: 'Generate video for bib number {startNumber}',
  },
  downloadVideoButton: {
    id: 'accountEventPage.downloadVideoButton',
    defaultMessage: 'Download video for bib number {startNumber}',
  },
});

AccountEventPage.propTypes = {
  intl: PropTypes.object,
  viewer: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export { AccountEventPage };

export default createFragmentContainer(injectIntl(AccountEventPage), {
  viewer: graphql`
    fragment AccountEventPage_viewer on Viewer {
      account {
        orders(first: 100) {
          edges {
            node {
              id
              cart {
                lineItems {
                  product {
                    event {
                      name
                      sgId
                    }
                  }
                }
              }
              orderid
              vouchers {
                id
                code
                campaign {
                  id
                  banner
                  url
                }
              }
            }
          }
        }
        boughtEvent(eventId: $eventId) {
          name
          products {
            type
            price
          }
          sgId
          mainSearch
          pendingMediaProcessing
          ...EventInfoCard_eventInfo
        }
        eventPurchases(eventId: $eventId) {
          availableCertificates
          availableVideos
          availableZips
          bonusMedia {
            mediaInfo {
              count
            }
            mediaList(first: 1000) {
              edges {
                node {
                  id
                  sgId
                  variants {
                    format
                    name
                    url
                  }
                }
              }
              ...MediaList_media
            }
          }
          canRestart
          restartState
          participants {
            media {
              mediaInfo {
                count
              }
              mediaList(first: 1000) {
                edges {
                  node {
                    id
                    sgId
                    variants {
                      format
                      name
                      url
                    }
                  }
                }
                ...MediaList_media
              }
            }
            startnumber
          }
          singleMedia {
            mediaInfo {
              count
            }
            mediaList(first: 1000) {
              edges {
                node {
                  id
                  sgId
                  variants {
                    format
                    name
                    url
                  }
                }
              }
              ...MediaList_media
            }
          }
        }
      }
    }
  `,
});
