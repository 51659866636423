import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FormattedMessageWrappedInSpan } from '../../misc';

const ENTER_KEY = 'Enter';

const CertificateDownloadForm = (props) => {
  const { intl, onDownloadCertificate, pendingRequests, failed } = props;

  const [showError, setShowError] = useState(false);
  const [startnumber, setStartnumber] = useState('');

  const isPending = () => {
    return pendingRequests.length > 0;
  };

  const handleStartnumberInputChanged = (event) => {
    setShowError(false);
    setStartnumber(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === ENTER_KEY && !isPending()) {
      submitForm();
    }
  };

  const submitForm = () => {
    if (!startnumber) {
      setShowError(true);
      return;
    }

    onDownloadCertificate(startnumber.trim());
  };

  const getErrorMessage = () => {
    if (showError) {
      return intl.formatMessage(translations.inputErrorText);
    }
    if (failed) {
      return intl.formatMessage(translations.outcomeErrorText);
    }

    return false;
  };

  const not_ready = () => {
    return !startnumber || isPending();
  };

  return (
    <div className="notification-container">
      <DialogContent>
        <div className="notification-body">
          <h1>
            <FormattedMessageWrappedInSpan
              id="thumbnailPreviewAccountDetails.certificateDownloadForm.title"
              defaultMessage="Enter your bib number"
              className="mb-15"
            />
          </h1>
          <FormGroup>
            <TextField
              autoComplete="off"
              autoFocus={startnumber === ''}
              className="notification-input"
              error={getErrorMessage()}
              id="startnumber"
              helperText={getErrorMessage()}
              label={intl.formatMessage(translations.startnumberTextFieldLabel)}
              onChange={handleStartnumberInputChanged}
              onKeyPress={handleKeyPress}
              placeholder={intl.formatMessage(
                translations.startnumberTextFieldPlaceholder
              )}
              value={startnumber}
            />
          </FormGroup>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="notification-action with-spinner">
          <button
            className={classNames('accept', { disabled: not_ready() })}
            disabled={not_ready()}
            onClick={submitForm}
          >
            <FormattedMessageWrappedInSpan
              id="thumbnailPreviewAccountDetails.certificateDownloadForm.acceptButton"
              defaultMessage="Download"
            />
            {isPending() && (
              <div className="download-dropdown-expand-icon-container">
                <CircularProgress
                  classes={{ circle: 'circular-progress-circle-copy-color-medium' }}
                  size={20}
                  thickness={3}
                />
              </div>
            )}
          </button>
        </div>
      </DialogActions>
    </div>
  );
};

const translations = defineMessages({
  startnumberTextFieldLabel: {
    id: 'thumbnailPreviewAccountDetails.certificateDownloadForm.startnumberTextFieldLabel',
    defaultMessage: 'Bib Number',
  },
  startnumberTextFieldPlaceholder: {
    id: 'thumbnailPreviewAccountDetails.certificateDownloadForm.startnumberTextFieldPlaceholder',
    defaultMessage: 'Enter one bib number',
  },
  inputErrorText: {
    id: 'thumbnailPreviewAccountDetails.certificateDownloadForm.inputErrorText',
    defaultMessage: 'This field is required',
  },
  outcomeErrorText: {
    id: 'thumbnailPreviewAccountDetails.certificateDownloadForm.outcomeErrorText',
    defaultMessage: 'Could not download. Have you given a correct bib number?',
  },
});

CertificateDownloadForm.propTypes = {
  intl: PropTypes.object,
  onDownloadCertificate: PropTypes.func,
  pendingRequests: PropTypes.array,
  failed: PropTypes.bool,
};

export default injectIntl(CertificateDownloadForm);
export { CertificateDownloadForm };
