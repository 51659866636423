import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessageWrappedInSpan } from '../../misc';

import {
  ORIGINAL,
  SINGLE_PHOTO,
  VIDEO,
  CERTIFICATE,
  CERTIFICATE_UNKNOWN_BIB,
  CERTIFICATE_SELECTED_PHOTO,
  SOCIAL_MEDIA,
  VOUCHER,
  RESTART_PRODUCTION,
} from '../../../utils/variables';

import PhotoIcon from '@material-ui/icons/Photo';
import TheatersRoundedIcon from '@material-ui/icons/TheatersRounded';
import CardGiftcardRoundedIcon from '@material-ui/icons/CardGiftcardRounded';
import CardMembershipRoundedIcon from '@material-ui/icons/CardMembershipRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StayCurrentPortraitRoundedIcon from '@material-ui/icons/StayCurrentPortraitRounded';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';

import AccountDownloadTileZipDownload from './AccountDownloadTileZipDownload';
import AccountDownloadTileVideo from './AccountDownloadTileVideo';
import AccountDownloadTileVoucher from './AccountDownloadTileVoucher';
import AccountDownloadDialogTile from './AccountDownloadDialogTile';
import AccountRestartTile from './AccountRestartTile';
import AccountDownloadTileButton from './AccountDownloadTileButton';

const AccountDownloadTile = ({
  type,
  notActive,
  onScrollToMedia,
  values,
  eventSgId,
  isFaceSearchEvent,
  intl,
  areProcessed,
  isError,
  isPending,
  hasOptions,
  hasOpenedOptions,
  onCertificateDownloadClick,
  onCloseOptions,
  children,
  onOpenCertificateDialog,
  isWrappedInLink,
}) => {
  switch (type) {
    case SINGLE_PHOTO:
      return (
        <AccountDownloadDialogTile
          icon={<PhotoIcon className="account-tile-icon" />}
          textInSpan={
            <FormattedMessageWrappedInSpan
              id="messages.singlePhoto"
              defaultMessage="Single Photo"
            />
          }
          type={type}
          onScrollToMedia={onScrollToMedia}
        />
      );
    case SOCIAL_MEDIA:
      return (
        <AccountDownloadTileZipDownload
          icon={<StayCurrentPortraitRoundedIcon className="account-tile-icon" />}
          textInSpan={
            <FormattedMessageWrappedInSpan
              id="messages.socialMediaRes"
              defaultMessage="Social Media Resolution"
            />
          }
          type={type}
          eventSgId={eventSgId}
          values={SOCIAL_MEDIA}
          isWrappedInLink={isWrappedInLink}
        />
      );
    case RESTART_PRODUCTION:
      return (
        <AccountRestartTile
          icon={<ReplayRoundedIcon className="account-tile-icon" />}
          textInSpan={
            <FormattedMessageWrappedInSpan
              id="accountEventPage.actions.requestHiRes"
              defaultMessage="Request High Resolution"
            />
          }
          type={type}
          eventSgId={eventSgId}
        />
      );
    case ORIGINAL:
      if (areProcessed) {
        return (
          <AccountDownloadDialogTile
            icon={<StarRoundedIcon className="account-tile-icon" />}
            textInSpan={
              <FormattedMessageWrappedInSpan
                id="messages.highRes"
                defaultMessage="High Resolution"
              />
            }
            type={type}
            notActive={true}
          />
        );
      } else
        return (
          <AccountDownloadTileZipDownload
            icon={<StarRoundedIcon className="account-tile-icon" />}
            textInSpan={
              <FormattedMessageWrappedInSpan
                id="messages.highRes"
                defaultMessage="High Resolution"
              />
            }
            type={type}
            eventSgId={eventSgId}
            values={values}
            isWrappedInLink={isWrappedInLink}
          />
        );
    case VIDEO:
      return (
        <AccountDownloadTileVideo
          icon={<TheatersRoundedIcon className="account-tile-icon" />}
          textInSpan={
            <FormattedMessageWrappedInSpan
              id="accountEventPage.products.video"
              defaultMessage="Video"
            />
          }
          notActive={notActive}
          type={type}
          values={values}
          eventSgId={eventSgId}
          isFaceSearchEvent={isFaceSearchEvent}
          intl={intl}
        />
      );
    case CERTIFICATE:
      return (
        <AccountDownloadDialogTile
          icon={<CardMembershipRoundedIcon className="account-tile-icon" />}
          textInSpan={
            <FormattedMessageWrappedInSpan
              id="accountEventPage.products.certificate"
              defaultMessage="Certificate"
            />
          }
          type={type}
          onScrollToMedia={onScrollToMedia}
          notActive={notActive}
        />
      );
    case CERTIFICATE_SELECTED_PHOTO:
      return (
        <AccountDownloadTileButton
          icon={<CardMembershipRoundedIcon className="account-tile-icon" />}
          textInSpan={
            <FormattedMessageWrappedInSpan
              id="accountEventPage.products.certificate"
              defaultMessage="Certificate"
            />
          }
          type={type}
          isPending={isPending}
          hasOptions={hasOptions}
          onClick={onCertificateDownloadClick}
          hasOpenedOptions={hasOpenedOptions}
          onCloseOptions={onCloseOptions}
          error={isError}
        >
          {children}
        </AccountDownloadTileButton>
      );
    case CERTIFICATE_UNKNOWN_BIB:
      return (
        <AccountDownloadTileButton
          icon={<CardMembershipRoundedIcon className="account-tile-icon" />}
          textInSpan={
            <FormattedMessageWrappedInSpan
              id="accountEventPage.products.certificate"
              defaultMessage="Certificate"
            />
          }
          type={type}
          onClick={onOpenCertificateDialog}
        />
      );
    case VOUCHER:
      return (
        <AccountDownloadTileVoucher
          icon={<CardGiftcardRoundedIcon className="account-tile-icon" />}
          textInSpan={
            <FormattedMessageWrappedInSpan
              id="accountEventPage.products.voucher"
              defaultMessage="Voucher"
            />
          }
          values={values}
        />
      );
  }
};

AccountDownloadTile.propTypes = {
  type: PropTypes.string,
  values: PropTypes.array,
  notActive: PropTypes.bool,
  onScrollToMedia: PropTypes.func,
  eventSgId: PropTypes.number,
  isFaceSearchEvent: PropTypes.bool,
  intl: PropTypes.object,
  onRequestHighResolutionPhoto: PropTypes.func,
  isError: PropTypes.bool,
  isPending: PropTypes.bool,
  hasOptions: PropTypes.bool,
  hasOpenedOptions: PropTypes.bool,
  onCertificateDownloadClick: PropTypes.func,
  onCloseOptions: PropTypes.func,
  children: PropTypes.node,
  onOpenCertificateDialog: PropTypes.func,
  isWrappedInLink: PropTypes.func,
};

export default AccountDownloadTile;
