import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';

import { FormattedMessageWrappedInSpan } from '../misc';

import {
  FACTOR_EMAIL,
  FACTOR_DATE_OF_BIRTH,
  FACTOR_YEAR_OF_BIRTH,
  FACTOR_CODE,
} from '../../utils/variables';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

const SecondFactorErrorMsg = (props) => {
  const { event, userLoginEmail, onLoginChange, isLoggedIn, userIsOnTheNumberSearch } = props;
  const startnumber = useParams().startnumber;
  const history = useHistory();
  const showAdditionalActionsForUser = /search/.test(window.location.href) && userIsOnTheNumberSearch;
  const eventPath = generatePath('/:language/event/:id', {
    id: useParams().id,
    language: useParams().language,
  });

  const logoutAsync = () => {
    return new Promise((resolve, reject) => {
      onLoginChange(false);
      resolve();
    });
  };

  const _renderSecondFactorAuthorisationMessage = (type, logoutAsync) => {
    switch (type) {
      case FACTOR_DATE_OF_BIRTH:
        return (
          <>
            <h2>
              <FormattedMessageWrappedInSpan
                id="eventSnSearchPage.unauthorised.secondFactor.birthDateType.title"
                defaultMessage="Date of birth does not match!"
              />
            </h2>
            <p>
              <FormattedMessageWrappedInSpan
                id="eventSnSearchPage.unauthorised.secondFactor.description"
                defaultMessage="Please check your search query. Only the information you used to register for the event will be accepted."
              />
            </p>
          </>
        );
      case FACTOR_YEAR_OF_BIRTH:
        return (
          <>
            <h2>
              <FormattedMessageWrappedInSpan
                id="eventSnSearchPage.unauthorised.secondFactor.yearOfBirthType.title"
                defaultMessage="Year of birth does not match!"
              />
            </h2>
            <p>
              <FormattedMessageWrappedInSpan
                id="eventSnSearchPage.unauthorised.secondFactor.description"
                defaultMessage="Please check your search query. Only the information you used to register for the event will be accepted."
              />
            </p>
          </>
        );
      case FACTOR_CODE:
        return (
          <>
            <h2>
              <FormattedMessageWrappedInSpan
                id="eventSnSearchPage.unauthorised.secondFactor.codeType.title"
                defaultMessage="Access code does not match!"
              />
            </h2>
            <p>
              <FormattedMessageWrappedInSpan
                id="eventSnSearchPage.unauthorised.secondFactor.codeType.description"
                defaultMessage="Please check your entered bib number and access code. Only the information provided to you by the events organizer will be accepted."
              />
            </p>
            <div className="unauthorised-info-container-actions">
              {' '}
              <button
                className="btn-default primary-new"
                onClick={() => {
                  props.onScrollToForm();
                }}
              >
                <FormattedMessageWrappedInSpan
                  id="eventSnSearchPage.unauthorised.secondFactor.codeType.action"
                  defaultMessage="Try other details"
                />
              </button>
            </div>
          </>
        );
      case FACTOR_EMAIL:
        return (
          <>
            <h2>
              <FormattedMessageWrappedInSpan
                id="eventSnSearchPage.unauthorised.secondFactor.emailType.title"
                defaultMessage="Email<span>{userEmail}</span> does not match bib number{startnumber}!"
                values={{
                  span: (chunks) => (
                    <span className="unauthorised-info-container-text-highlight">
                      {chunks}
                    </span>
                  ),
                  userEmail: userLoginEmail ? ` ${userLoginEmail}` : '',
                  startnumber: startnumber ? ` ${startnumber}` : '',
                }}
              />
            </h2>
            <p>
              {showAdditionalActionsForUser ? (
                <FormattedMessageWrappedInSpan
                  id="eventSnSearchPage.unauthorised.secondFactor.emailType.descriptionWithAction"
                  defaultMessage="Make sure you use <b>the same email address and bib number that you used to register for the event</b>. You can also try another email or try another bib number."
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              ) : (
                <FormattedMessageWrappedInSpan
                  id="eventSnSearchPage.unauthorised.secondFactor.emailType.description"
                  defaultMessage="Make sure you use <b>the same email address that you used to register for the event</b>."
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              )}
            </p>
            {showAdditionalActionsForUser && (
              <div className="unauthorised-info-container-actions">
                {' '}
                <button
                  className="btn-default primary-new"
                  onClick={() => {
                    props.onScrollToForm();
                  }}
                >
                  <FormattedMessageWrappedInSpan
                    id="eventSnSearchPage.unauthorised.secondFactor.emailType.action.startnumber"
                    defaultMessage="Try another bib number"
                  />
                </button>
                <button
                  className="btn-default primary-new"
                  onClick={() => {
                    if (isLoggedIn) {
                      logoutAsync().then(() => {
                        history.push(eventPath);
                      });
                    } else {
                      history.push(eventPath);
                    }
                  }}
                >
                  <FormattedMessageWrappedInSpan
                    id="eventSnSearchPage.unauthorised.secondFactor.emailType.action.email"
                    defaultMessage="Try another email"
                  />
                </button>
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="row">
      <div className="col-xs-16 col-sm-12 col-sm-offset-2">
        <div className="unauthorised-info-container">
          {_renderSecondFactorAuthorisationMessage(
            event.secondFactorType.type,
            logoutAsync
          )}
        </div>
      </div>
    </div>
  );
};

SecondFactorErrorMsg.propTypes = {
  event: PropTypes.object,
  onScrollToForm: PropTypes.func,
  userLoginEmail: PropTypes.string,
  onLoginChange: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  userIsOnTheNumberSearch: PropTypes.bool,
};

export default SecondFactorErrorMsg;
